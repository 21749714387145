<template>
  <div class="app-menu">
    <appLogo></appLogo>
    <div class="h-menu h-menu-dark h-menu-mode-vertical">
      <ul>
        <li class="h-menu-li h-menu-first-level" v-for="m in menus" @click="trigger(m)">
          <div class="h-menu-show" :class="{' h-menu-li-selected':selected(m)}">
              <span class="h-menu-show-icon" v-if="m.icon">
                <i :class="m.icon"></i>
              </span>
            <span class="h-menu-show-desc text-16px">{{ m.title }}</span>
            <span class="h-menu-show-expand" v-if="m.children"><i class="h-icon-angle-down"></i></span>
          </div>
          <div class=" h-menu-other-levels !flex" :class="{'flex-column':m.children.filter(val=>val.children).length===0}" v-if="m.children && m.children.length">
            <template v-for="c1 in m.children">
              <template v-if="c1.children && c1.children.length">
                <div class="flex-1 m-8px">
                  <div class="py-8px px-4px primary-color font-bold text-14px py-17px"> {{ c1.title }}</div>
                  <div @click.stop="trigger(c2)" class="p-6px text-hover text-14px" :class="currentTab === c2.key?'font-bold white-color':''" v-for="c2 in c1.children" :key="c2.key">
                    {{ c2.title }}
                  </div>
                </div>
              </template>
              <div v-else :class="currentTab === c1.key?'font-bold white-color bg-primary-color':''" class="py-8px px-16px text-hover text-14px py-17px" @click.stop="trigger(c1)">
                {{ c1.title }}
              </div>
            </template>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapState} from 'vuex';
import appLogo from './app-logo';
import $ from 'jquery';

export default {
  name: "AppMenu",
  props: {
    theme: String
  },
  computed: {
    ...mapState(['User', 'sliderCollapsed', 'currentAccountSets', 'currentTab', 'menus', 'reportList']),
    ...mapGetters(['modulesMap'])
  },
  methods: {
    ...mapMutations(['newTab']),
    selected(item) {
      if (this.menus) {
        let find = this.menus.find(val => {
          if (val.key === this.currentTab) {
            return true;
          } else if (val.children) {
            for (const c1 of val.children) {
              if (c1.key === this.currentTab) {
                return true;
              } else if (c1.children) {
                for (const c2 of c1.children) {
                  if (c2.key === this.currentTab) {
                    return true;
                  }
                }
              }
            }
          }
        })
        return find && (item.key === find.key);
      }
      return false;
    },
    trigger(data) {
      if (!data.children) {
        this.newTab(data);
      }
    },
    hideMenu() {
      this.$store.commit('updateSliderCollapse', true);
    }
  },
  components: {
    appLogo
  },
  created() {
    if (this.menus) {
      const reportList = this.menus.find(val => val.key === "ReportList")
      const children = [];
      this.reportList.forEach(item => {
        item.dimensions.forEach(d => {
          children.push({
            title: item.name + (d === 'QUARTER' ? "季报" : ''),
            key: "ReportView:" + item.templateKey + (d === 'QUARTER' ? ":" + d : ''),
            params: {
              reportId: item.templateKey,
              dimension: d
            }
          })
        })
      })
      children.push(...reportList?.children || [])
      reportList && (reportList.children = children);
    }
  },
  mounted() {
    $('.h-menu .h-menu-first-level').hover((e) => {
      const maxHeight = window.document.body.scrollHeight;
      const other = $('.h-menu-other-levels', e.currentTarget);
      if (other.length) {
        const offset = $(e.currentTarget).offset()
        const height = other.height()
        if (offset.top + height > maxHeight) {
          other.offset({
            top: maxHeight - height - 30
          })
        } else {
          other.offset({
            top: offset.top
          })
        }
      }
    })
  }
};
</script>
<style lang="less" scoped>
.h-menu-mode-vertical {
  > .h-menu-li {
    > .h-menu-show {
      padding-left: 15px;
    }
  }

  .h-menu-li-selected {
    color: @white-color !important;
  }

  .h-menu-show {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    color: #b3b3b3;
    padding: 15px 10px;
    transition: color @transition-time;

    &-icon {
      width: 18px;
      height: 18px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

    &-expand {
      > i {
        transition: transform @transition-time;
        transform: rotate(-90deg);
      }

      right: 10px;
    }
  }

  .h-menu-other-levels {
    box-shadow: @box-shadow;
    margin-left: 5px;
    left: 100%;
    top: 0;
    border-radius: 4px;
    position: absolute;
    width: 0;
    transition: 0.2s cubic-bezier(0.78, 0.17, 0.43, 1);
    white-space: nowrap;
    display: inline-block;
    opacity: 0.8;
    transform: scale(0.8);
    transform-origin: left;
    max-height: initial !important;

    > div:first-child {
      border-radius: 5px 5px 0 0;
    }

    > div:last-child {
      border-radius: 0 0 5px 5px;
    }

    .h-menu-show {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > div:hover {
      color: @white-color !important;
      background-color: #3a3a3a;
      font-weight: bold;
    }
  }

  .h-menu-li:hover {
    > .h-menu-other-levels {
      min-width: 170px;
      opacity: 1;
      transform: scale(1);
      overflow: visible;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        left: -10px;
        width: 10px;
      }
    }
  }
}

.h-menu.h-menu-mode-collapse .h-menu-other-levels {
  width: 0 !important;
  max-width: none !important;
}

.h-menu.h-menu-mode-collapse {
  .h-menu-li:hover {
    .h-menu-other-levels {
      width: auto !important;
      color: @white-color;
    }
  }
}

.h-menu-dark .h-menu-show:hover {
  background-color: #3a3a3a;
}
</style>
