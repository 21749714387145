<template>
  <div class="frame-page">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "AppContent",
  props: {
    title: String
  }
}
</script>
