import {createApp} from 'vue'
import App from './views/App.vue';
import Login from './views/Login.vue';
import store from './store';
import router from './router';
import heyui from "heyui.ext";
import {useTable} from '@js/common/xe-table';
import filters from './js/common/filters';
import directives from './js/directives';
import components from './components';
import '@layui/layer-vue/lib/index.css';
import layer from '@layui/layer-vue';

import './js/config/dict';
import 'windi.css'
import 'font-awesome/css/font-awesome.css'
import './styles/app.less';

let app;
store.dispatch("init").then(() => {
  app = createApp(App);
  app.use(directives)
    .use(components)
    .use(useTable)
    .use(layer)
    .use(filters)
    .use(router)
    .use(heyui)
    .use(store);
}).catch((err) => {
  app = createApp(Login).use(store).use(heyui).use(filters);
}).finally(() => {
  Object.keys(process.env).forEach(key => {
    if (key.startsWith("VUE_APP_")) {
      app.config.globalProperties[key.replace("VUE_APP_", "")] = process.env[key];
    }
  })
  app.mount('#app')
});
