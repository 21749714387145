<template>
  <div>

  </div>
</template>
<script>
export default {
  name: "AppFooter"
};
</script>
<style lang="less">
.app-footer {
  text-align: center;
  padding: 10px 0 30px;
  color: rgba(0, 0, 0, .45);

  a {
    color: rgba(0, 0, 0, .45);
  }
}
</style>
